import MiniSearch, { Query, SearchResult } from 'minisearch'

const tokenize = (text: string, _fieldName?: string) =>
  text.split(' ').map(v => v.trim()).filter(Boolean).map(v => v.toLowerCase());
const fourDigitYearRegex = /^(?:19|20|21|22)\d\d$/;
const fullDateRegexList = [
  /^(?<year>(?:19|20|21|22)?\d\d)[/-](?<month>\d(?:\d)?)[/-](?<day>\d(?:\d)?)$/,
  /^(?<month>\d(?:\d)?)[/-](?<day>\d(?:\d)?)[/-](?<year>(?:19|20|21|22)?\d\d)$/
]
const removeLeadingZeros = (term: string) => term.replace(/^0+/, '');
const processDate = (term: string) => {
  const terms = new Set<string>();
  fullDateRegexList.forEach(r => {
    const m = term.match(r);
    if (m?.groups) {
      terms.add(`${m.groups.year}-${removeLeadingZeros(m.groups.month)}-${removeLeadingZeros(m.groups.day)}`);
      terms.add(`${m.groups.year.slice(-2)}-${removeLeadingZeros(m.groups.month)}-${removeLeadingZeros(m.groups.day)}`);
    }
  });
  term.replace(/[^\d]/g, '-').split('-').map(v => v.trim()).filter(Boolean).forEach(v => {
    terms.add(v);
    terms.add(removeLeadingZeros(v));
    if (v.match(fourDigitYearRegex)) {
      terms.add(v.slice(2));
    }
  });
  return Array.from(terms);
}
const processTerm = (term: string, _fieldName?: string) =>
  _fieldName === 'm' ? processDate(term) : term;
const bondMiniSearchOptions = {
  fields: [
    'T', // ticker coupon maturity (Bloomberg ticker)
    'F', // figi
    'C', // cusip
    'I', // isin
    't', // ticker
    'c', // coupon
    'm', // maturity
    'i', // issuer
    'o', // amount outstanding
    's', // series
    'r', // rating
  ], // fields to index
  storeFields: ['T', 'C', 't', 'c', 'm', 'i', 'o', 's', 'r'], // fields to return with search results
  idField: 'F',
  tokenize,
  processTerm
};
const issuerMiniSearchOptions = {
  fields: [
    't', // ticker
    'i', // issuer
  ], // fields to index
  storeFields: ['i'], // fields to return with search results
  idField: 't',
  tokenize,
  processTerm
};

const uniqueAttribute = <T,>(list: T[], attribute: keyof T) => {
  const seen = new Set();
  return list.reduce((a, c) => {
    if (!seen.has(c[attribute])) {
      seen.add(c[attribute]);
      a.push(c);
    }
    return a;
  }, [] as T[]);
};

const tickerRegex = /^[a-z]+$/;
const couponRegex = /^\d+(?:\.\d*)?$/;
const maturityRegex = /^[\d/-]+$/;
const seriesRegex = /^1(4(4a?)?)?$|^r(e(gs?)?)?$/i;
const identifierRegex = /^[a-z0-9-]+$/;
const createBondSearchQuery = (query: string) => ({
  combineWith: 'OR',
  queries: tokenize(query).reduce((a: Query[], c) => {
    if (c.match(tickerRegex)) {
      a.push({
        queries: [c],
        fields: ['t'],
        prefix: false,
        boost: { 't': 50 }
      });
      a.push({
        queries: [c],
        fields: ['t'],
        prefix: true,
        boost: { 't': 4 }
      });
    }
    if (c.match(couponRegex)) {
      a.push({
        queries: [c],
        fields: ['c'],
        prefix: false,
        boost: { 'c': 25 }
      });
      a.push({
        queries: [c],
        fields: ['c'],
        prefix: true,
        boost: { 'c': 3 }
      });
    }
    if (c.match(maturityRegex)) {
      a.push({
        queries: [c],
        fields: ['m'],
        processTerm: (term) => processTerm(term, 'm'),
        boost: { 'm': 2 }
      });
    }
    if (c.match(seriesRegex)) {
      a.push({
        queries: [c],
        fields: ['s'],
        prefix: false,
        boost: { 's': 25 }
      });
      a.push({
        queries: [c],
        fields: ['s'],
        prefix: true,
        boost: { 's': 3 }
      });
    }
    if (c.match(identifierRegex)) {
      a.push({
        queries: [c],
        fields: ['C', 'F', 'I'],
        prefix: false,
        boost: { 'C': 100, 'F': 100, 'I': 100 }
      });
    }
    a.push({
      queries: [c],
      fields: ['i'],
      prefix: true,
      boost: { 'i': 1 }
    });
    return a;
  }, [])
});

export type BondIndexData = {
  T: string;
  F: string;
  C: string;
  I: string;
  t: string;
  c: number | '';
  m: string;
  i: string;
  o: number;
  s: string;
  r: string; // S&P Rating
};

export type Bond = {
  amountOutstanding: number;
  ticker: string;
  coupon: number;
  maturity: string;
  series: string;
  cusip: string;
  figi: string;
  issuer: string;
  rating: string;
  isin: string;
};

export type GetBond = ((figi: string) => Bond | null) | null;
export type GetBondByCusip = ((cusip: string) => Bond | null) | null;
export type GetBondByIsin = ((isin: string) => Bond | null) | null;

const createIssuerSearchQuery = (query: string) => ({
  combineWith: 'OR',
  queries: tokenize(query).reduce((a: Query[], c) => {
    a.push({
      queries: [c],
      fields: ['t'],
      prefix: false,
      boost: { 't': 50 }
    });
    a.push({
      queries: [c],
      fields: ['t'],
      prefix: true,
      boost: { 't': 4 }
    });
    a.push({
      queries: [c],
      fields: ['i'],
      prefix: false,
      boost: { 'i': 50 }
    });
    a.push({
      queries: [c],
      fields: ['i'],
      prefix: true,
      boost: { 'i': 4 }
    });
    return a;
  }, [])
});

type IssuerIndexData = {
  t: string;
  i: string;
};

export type Issuer = {
  ticker: string;
  issuer: string;
};

const yearFormatter = new Intl.DateTimeFormat('en', { year: 'numeric' });
const monthFormatter = new Intl.DateTimeFormat('en', { month: '2-digit' });
const dayFormatter = new Intl.DateTimeFormat('en', { day: '2-digit' });
const formatDate = (d: Date) => `${monthFormatter.format(d)}/${dayFormatter.format(d)}/${yearFormatter.format(d)}`;

const bondSearchResultToBond = (s: SearchResult): Bond => ({
  amountOutstanding: s.o,
  cusip: s.C,
  figi: s.id,
  ticker: s.t,
  coupon: +s.c,
  maturity: formatDate(new Date(s.m)),
  series: s.s,
  issuer: s.i,
  rating: s.r,
  isin: s.I,
});

const issuerSearchResultToIssuer = (s: SearchResult): Issuer => ({
  ticker: s.id,
  issuer: s.i
});

type CreateSearchFunctions = (items: BondIndexData[]) => { 
  getBond: (figi: string) => Bond | null, 
  getBondByCusip: (cusip: string) => Bond | null,
  getBondByIsin: (isin: string) => Bond | null,
  getIssuer: (ticker: string) => Issuer | null, 
  getIssuerBonds: (ticker: string) => Bond[], 
  searchBonds: (query: string) => Bond[], searchIssuers: (query: string) => Issuer[] 
}

export const createSearchFunctions: CreateSearchFunctions  = (items) => {
  // create index and add all items
  const bondIndex = new MiniSearch(bondMiniSearchOptions);
  bondIndex.addAll(uniqueAttribute(items, 'F').map(b => {
    const T = b.T.toUpperCase().trimEnd();
    return {
      ...b,
      s: T.endsWith('144A') ? '144A'
        : T.endsWith('REGS') ? 'REGS'
        : ''
    };
  }));
  const issuerIndex = new MiniSearch(issuerMiniSearchOptions);
  issuerIndex.addAll(uniqueAttribute(items.map(i => ({ t: i.t, i: i.i }) as IssuerIndexData), 't'));
  return {
    getBond: figi => {
      const results = bondIndex.search(figi, { fields: ['F'], prefix: false }).map(bondSearchResultToBond);
      return results.length ? results[0] : null;
    },
    getBondByCusip: cusip => {
      const results = bondIndex.search(cusip, { fields: ['C'], prefix: false }).map(bondSearchResultToBond);
      return results.length ? results[0] : null;
    },
    getBondByIsin: isin => {
      const results = bondIndex.search(isin, { fields: ['I'], prefix: false }).map(bondSearchResultToBond);
      return results.length ? results[0] : null;
    },
    getIssuer: ticker => {
      const results = issuerIndex.search(ticker, { fields: ['t'], prefix: false }).map(issuerSearchResultToIssuer);
      return results.length ? results[0] : null;
    },
    getIssuerBonds: ticker => bondIndex.search(ticker, { fields: ['t'], prefix: false }).map(bondSearchResultToBond),
    searchBonds: query => bondIndex.search(createBondSearchQuery(query)).map(bondSearchResultToBond),
    searchIssuers: query => issuerIndex.search(createIssuerSearchQuery(query)).map(issuerSearchResultToIssuer)
  };
}
